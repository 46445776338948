<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { ddlHelper, sessionHelper } from "@/utils";
import { SESSION } from "@/constants";
import { required, email } from "vuelidate/lib/validators";
import moment from "moment";
import { mapState } from "vuex";
import i18n from "@/i18n";
import { USER_MODULE } from '@/state/modules/user'
import { LOOKUP_MODULE } from '@/state/modules/lookup'
import { lookupMethods, userMethods } from "@/state/helpers";

const checkFalse = (value) => value != false;

export default {
  page: {
    title: i18n.t("label.updateFormX", [i18n.t("module.user")]),
  },
  components: { Layout, PageHeader },
  beforeRouteLeave(to, from, next) {
    if (to.name != "User-listing") {
      sessionHelper.clearSearch(SESSION.USER_SEARCH);
    }
    next();
  },
  computed: {
    ...mapState(USER_MODULE, ["status"]),
    ...mapState(LOOKUP_MODULE),
    roleDdl() {
      return this.$store.state.lookup.roleDdl;
    },
    data() {
      return this.$store.state.user.details;
    },
    date() {
      return moment(this.$store.state.user.details.createdDateTime).format(
        "MMMM Do YYYY"
      );
    },
  },
  data() {
    return {
      title: i18n.t("label.updateFormX", [i18n.t("module.user")]),
      items: [
        {
          text: i18n.t("module.dashboard"),
          href: "/",
        },
        {
          text: i18n.t("module.user"),
          href: "/user",
        },
        {
          text: i18n.t("label.updateX", [i18n.t("module.user")]),
          active: true,
        },
      ],
      submitted: false,
      statusDdl: ddlHelper.getActivationStatus(false, true),
    };
  },
  validations: {
    data: {
      name: { required },
      email: { required, email },
      isActive: { required },
      roleId: { checkFalse },
    },
  },
  created() {
    this.searchCriteria = sessionHelper.retrieveSearch(SESSION.USER_SEARCH);
    
    this.getRoleDdl();
    this.getUserDetails();
  },
  methods: {
    ...userMethods,
    ...lookupMethods,

    getRoleDdl() {
      this.lookupRole({ hasOptionAll: false });    
    },
    getUserDetails() {
      this.getUser({ id: this.searchCriteria.selectedRecordId, });
    },
    requestUpdate() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let data = {
          id: this.data.id,
          name: this.data.name,
          email: this.data.email,
          roleId: this.data.roleId,
        };
        this.updateUser({ data });
      }
    },
    requestUnlock() {
      let id = this.data.id;
      this.unlockUser({ id });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="requestUpdate">
              <b-form-group
                id="name"
                label-cols-sm="2"
                label-cols-lg="2"
                :label="$t('label.name')"
                label-for="name"
              >
                <b-form-input
                  for="name"
                  v-model.trim="data.name"
                  :class="{ 'is-invalid': !$v.data.name.required }"
                ></b-form-input>
                <div v-if="!$v.data.name.required" class="invalid-feedback">
                  <span v-if="!$v.data.name.required">{{
                    $t("message.requiredX", [$t("label.name")])
                  }}</span>
                </div>
              </b-form-group>

              <b-form-group
                id="email"
                label-cols-sm="2"
                label-cols-lg="2"
                :label="$t('label.email')"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model.trim="data.email"
                  :class="{
                    'is-invalid':
                      !$v.data.email.required || !$v.data.email.email,
                  }"
                ></b-form-input>
                <div
                  v-if="!$v.data.email.required || !$v.data.email.email"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.data.email.required">{{
                    $t("message.requiredX", [$t("label.email")])
                  }}</span>
                  <span v-if="!$v.data.email.email">{{
                    $t("message.validX", [$t("label.email")])
                  }}</span>
                </div>
              </b-form-group>

              <b-form-group
                id="isVerified"
                label-cols-sm="2"
                label-cols-lg="2"
                :label="$t('label.verified')"
                label-for="isVerified"
              >
                <b-form-input
                  id="isVerified"
                  :value="
                    data.isVerified === true ? $t('label.verified') : $t('label.nonVerified')
                  "
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="isLocked"
                label-cols-sm="2"
                label-cols-lg="2"
                :label="$t('label.accountStatus')"
                label-for="isLocked"
              >
                <b-form-input
                  id="isLocked"
                  :value="data.isLocked === true ? $t('label.lock') : $t('label.active')"
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="createdDateTime"
                label-cols-sm="2"
                label-cols-lg="2"
                :label="$t('label.createdDateTime')"
                label-for="createdDateTime"
              >
                <b-form-input
                  id="createdDateTime"
                  v-model="date"
                  type="datetime"
                  disabled
                ></b-form-input>
              </b-form-group>

              <div class="form-group row mb-0" style="padding-bottom: 10px">
                <label class="col-md-2 col-form-label">{{$t('label.userStatus')}}</label>
                <div class="col-md-10">
                  <select class="custom-select" v-model.trim="data.isActive">
                    <option
                      v-for="status in statusDdl"
                      :value="status.value"
                      v-bind:key="status.value"
                    >
                      {{ status.text }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row mb-0" style="display: none;">
                <label class="col-md-2 col-form-label">{{$t('module.role')}}</label>

                <div class="row">
                  <div
                    class="col-sm-5 py-2"
                    v-for="role in roleDdl"
                    v-bind:key="role.value"
                  >
                    <div class="form-check">
                      <b-form-checkbox
                        type="checkbox"
                        v-model="data.roleId"
                        v-bind:value="role.value"
                        v-bind:id="role.value"
                        disabled
                      >
                        {{ role.text }}
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div
                    v-if="!$v.data.roleId.checkFalse"
                    style="color: #ff3d60; font-size: 80%"
                  >
                    <span v-if="!$v.data.roleId.checkFalse">{{
                      $t("message.requiredX", [$t("module.role")])
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="mt-4 text-center" style="float: right">
                <button
                  class="btn btn-primary w-md waves-effect waves-light"
                  style="margin-right: 10px"
                  type="submit"
                  :disabled="status.update"
                >
                  {{ $t("label.updateX", [$t('module.user')]) }}
                </button>

                <button
                  class="btn btn-primary w-md waves-effect waves-light"
                  type="button"
                  @click="requestUnlock"
                  :disabled="status.update"
                >
                  {{$t('label.unlockAccount')}}
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>